/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* navigation-large.less 2013-1-16 *******/
.navi,
.navi > .item,
.dock {
  display: block;
  float: left;
}
.menu {
  display: block;
}
/* LEVEL 1 */
div.sub1 {
  float: right;
}
.sub1 > .item {
  margin: 0 15px;
  position: relative;
}
.sub1 > .item.init {
  margin-left: 0;
}
.sub1 > .item.exit {
  margin-right: 0;
}
div.sub1 .menu {
  font-size: 16px;
  line-height: 1.25;
  text-decoration: none;
  font-weight: normal;
  font-family: 'Frutiger65Bold', helvetica, sans-serif;
  padding: 30px 0;
  position: relative;
  white-space: nowrap;
}
div.sub1 .menu:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 2;
  width: 0;
  background: #004896;
  height: 3px;
}
div.sub1 .menu:hover,
div.sub1 .menu:focus,
div.sub1 .menu.path {
  color: #004896;
}
div.sub1 .menu:hover:after,
div.sub1 .menu:focus:after,
div.sub1 .menu.path:after {
  width: 100%;
  left: 0;
}
div.sub1 .item.c2 .menu {
  color: #46474F;
}
div.sub1 .item.c2 .menu:after {
  background: #46474F;
}
/* LEVEL 2 */
div.sub2 {
  position: absolute;
  top: 100%;
  background: #F7F7F7;
  padding: 23px 30px 21px;
  margin-left: -30px;
  display: none;
}
div.sub1 > .item.exit div.sub2 {
  right: 0;
  margin-left: 0;
  margin-right: -30px;
}
.sub2 > .item {
  width: 100%;
}
div.sub2 .menu {
  padding: 4px 0;
}
div.sub2 .menu:after {
  display: none;
}
div.sub2 .menu:hover,
div.sub2 .menu:focus,
div.sub2 .menu.path {
  color: #46474F;
}
div.sub1 .item.c2 div.sub2 .menu:hover,
div.sub1 .item.c2 div.sub2 .menu:focus,
div.sub1 .item.c2 div.sub2 .menu.path {
  color: #004896;
}
.cbe--edit-navigation div.sub1 .item.path div.sub2,
div.sub1 .item:hover div.sub2 {
  display: block;
}
/******* layout-large.less 2013-1-16 *******/
.desk {
  max-width: 95.56868538%;
  width: 790px;
}
.desk.large,
.cb-layout4 .desk {
  width: 1294px;
}
.footarea {
  width: 102.31839258%;
  margin-left: -1.15919629%;
  margin-top: 60px;
}
.footpart {
  width: 17.67371601%;
  margin: 0 1.13293051%;
}
.footpart.footpart--special {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 8.3081571%;
}
#view.cb-layout4 div.main div.unit {
  transition: all 0.218s;
}
#view.cb-layout4 div.main div.unit:hover {
  box-shadow: 0 2px 11px rgba(109, 110, 116, 0.44);
}
#view.cb-layout4 div.main div.unit:active {
  box-shadow: none;
}
#view.cb-layout3 div.main div.unit {
  transition: all 0.218s;
}
#view.cb-layout3 div.main div.unit:hover,
#view.cb-layout3 div.main div.unit:focus {
  background: #f7f7f7;
}
div.fold div.head h2 {
  transition: background 0.218s;
}
#view div.fold div.head h2:hover,
#view div.fold div.head h2:focus {
  background: #f7f7f7;
}
.area {
  width: 820px;
}
.area > .unit {
  margin-right: 0px;
  margin-left: 0px;
  width: 820px;
}
.area h2,
.area .foot {
  margin-right: 15px;
  margin-left: 15px;
}
.area .part,
.area > .grid table {
  margin-right: 15px;
  margin-left: 15px;
  width: 790px;
}
.area .tiny {
  width: 380px;
}
.area > .slim {
  width: 410px;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 380px;
}
.area > .slim .tiny {
  margin-right: 15px;
  margin-left: 15px;
  width: 175px;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 15px;
  margin-left: 15px;
}
.cb-layout2 .main {
  width: 820px;
}
.cb-layout2 .main > .unit {
  margin-right: 0px;
  margin-left: 0px;
  width: 820px;
}
.cb-layout2 .main h2,
.cb-layout2 .main .foot {
  margin-right: 15px;
  margin-left: 15px;
}
.cb-layout2 .main .part,
.cb-layout2 .main > .grid table {
  margin-right: 15px;
  margin-left: 15px;
  width: 790px;
}
.cb-layout2 .main .tiny {
  width: 243px;
}
.cb-layout2 .main > .slim {
  width: 273.33333333px;
}
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  width: 243.33333333px;
}
.cb-layout2 .main > .slim h2,
.cb-layout2 .main > .slim .foot,
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  margin-right: 15px;
  margin-left: 15px;
}
.cb-layout4 .main {
  width: 100%;
}
.cb-layout4 .main > .unit {
  margin-right: 1.13293051%;
  margin-left: 1.13293051%;
  width: 97.73413897%;
}
.cb-layout4 .main h2,
.cb-layout4 .main .foot {
  margin-right: 2.31839258%;
  margin-left: 2.31839258%;
}
.cb-layout4 .main .part,
.cb-layout4 .main > .grid table {
  margin-right: 2.31839258%;
  margin-left: 2.31839258%;
  width: 95.36321484%;
}
.cb-layout4 .main .tiny {
  width: 45.36321484%;
}
.cb-layout4 .main > .slim {
  width: 22.73413897%;
}
.cb-layout4 .main > .slim h2,
.cb-layout4 .main > .slim .foot,
.cb-layout4 .main > .slim .part,
.cb-layout4 .main > .slim.grid table {
  margin-right: 9.96677741%;
  margin-left: 9.96677741%;
}
.cb-layout4 .main > .slim .part,
.cb-layout4 .main > .slim.grid table {
  width: 80.06644518%;
}
.cb-layout4 .main > .slim .tiny {
  width: 80.06644518%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.main {
  margin-left: -15px;
}
.cb-layout4 .main {
  margin-left: -1.15919629%;
  width: 102.31839258%;
}
.cb-layout3 div.main div.unit div.tiny {
  width: 380px;
}
.cb-layout4 div.main div.unit.wide {
  width: 47.73413897%;
}
.cb-layout4 div.main div.unit.wide div.head h2,
.cb-layout4 div.main div.unit.wide div.part {
  margin-left: 4.74683544%;
  margin-right: 4.74683544%;
}
.cb-layout4 div.main div.unit.wide div.tall {
  width: 90.50632911%;
}
.cb-layout4 div.main div.unit.wide div.tiny {
  width: 40.50632911%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -525px;
  width: 1050px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
th.prev,
th.next {
  font-weight: normal;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #004896;
  color: #fff;
}
.same,
.mese .same > span {
  background-color: #004896;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #003063;
}
.mese .same {
  background: none;
}
/******* module-album-large.less 2013-1-16 *******/
.cb-manual > .head > h2 {
  padding-right: 40px;
  min-height: 24px;
}
.aspt,
.cb-sort-mode {
  display: inline-block;
  background-color: #004896;
  color: #fff;
  text-align: center;
  text-decoration: none;
  min-height: 40px;
  border-radius: 0;
  padding: 0 20px;
  -webkit-appearance: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  float: left;
  text-shadow: none;
}
.aspt:hover,
.cb-sort-mode:hover,
.aspt:focus,
.cb-sort-mode:focus {
  color: #fff;
  background-color: #004fa5;
  text-decoration: none;
  cursor: pointer;
}
.aspt:active,
.cb-sort-mode:active {
  outline: none;
  background-color: #003063;
}
.aspt {
  margin-right: 0.5em;
}
/******* module-filter-large.less 2013-1-16 *******/
/*.beta span > .cb-hybrid,
.days td > .cb-hybrid,
.week td > .cb-hybrid,
.year td > .cb-hybrid,
.cats li > .cb-hybrid {
  text-decoration: underline;
}*/
.mese td > .cb-hybrid {
  background-color: #004fa5;
}
.mese td > .cb-hybrid:hover,
.mese td > .cb-hybrid:focus {
  background-color: #003c7d;
}
.mese tbody th,
.epoc td > .cb-hybrid,
.year td > .cb-hybrid,
.mese td > .cb-hybrid,
.week td > .cb-hybrid,
.days td > .cb-hybrid {
  padding: 0 0.3em;
}
.mese tbody th,
.mese tbody td {
  padding: 0 4% 0 2%;
}
.mese td > .cb-hybrid,
.mese td > span {
  padding: 0 0.1em;
}
.mese td > span {
  display: block;
}
.tabs .cb-hybrid,
.tabs .same > span,
.time .cb-hybrid,
.time .same > span,
.keys input,
.scan select {
  padding: 0;
}
.scan select {
  margin: 0;
  width: 100%;
}
.keys input {
  float: left;
  margin: 0 2% 0 0;
  padding: 0.23em;
  width: 71%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.keys button.cb-hybrid {
  display: block;
  float: left;
}
.font9 {
  font-size: 9px;
  font-size: 0.9rem;
}
.font12 {
  font-size: 12px;
  font-size: 1.2rem;
}
.font15 {
  font-size: 15px;
  font-size: 1.5rem;
}
.font18 {
  font-size: 18px;
  font-size: 1.8rem;
}
.font21 {
  font-size: 21px;
  font-size: 2.1rem;
}
.font24 {
  font-size: 24px;
  font-size: 2.4rem;
}
.font27 {
  font-size: 27px;
  font-size: 2.7rem;
}
.font30 {
  font-size: 30px;
  font-size: 3rem;
}
.font32 {
  font-size: 32px;
  font-size: 3.2rem;
}
/*# sourceMappingURL=./screen-large.css.map */